// CfgColumnPopup

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="250px">
      <v-card>
        <v-toolbar dense color="indigo lighten-1">
            <v-toolbar-title dense class="font-weight-light">New Column</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field v-if="dialog" v-model="item.column_name" label="column_name*"
                  required autofocus ref="autofocus"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCancel()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="onSubmit()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
	name: 'CfgColumnPopup',
	components: {},
    props: {
        model: {
            type: Object,
            default () {
                return {}
            }
        },
    },
	data() {
		return {
      dialog: false,
      item: { column_name: '', },
    }
	},
	computed: {},
	mounted() {},
	methods: {
			closeDialog(event) {
				  this.dialog = false
      		this.$store.commit('cfgtables/resetCfgColumn')
	      	this.$emit(event)
			},
    onSubmit() {
		        const data = this.item
	      		this.$store.dispatch('cfgtables/storeCfgColumn', data)
	      		.then((response) => {
	      			this.closeDialog('popup-submitted')
      			})
		    	.catch((error) => {
		    		console.log('column->submit->error')
			    	console.log(error)
			    	this.msgError(error.response.data)
			    });
    },
			onCancel() {
	      		this.closeDialog('popup-cancelled')
			},
	},
    watch: {
        model: {
            handler (val) {
                if (!val) return;
                this.dialog = val.dialog
                this.item.column_name = ''
                this.item.table_name = val.parent_name
                this.item.cfg_table_id = val.parent_id
                this.item.changed = 1
                if(this.dialog) {
                    requestAnimationFrame(() => {
                      console.log('focus')
                        return this.$refs.autofocus.focus();
                    })
                }
            },
            deep: true,
        },
    },
};
</script>
<style>
</style>